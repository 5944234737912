import SignalAnimation from "./SignalAnimation";
import { foregroundIMG, backgroundVID } from "../config";

export const BackgroundScene = () => {
  return (
    <>
      <div id="background">
        <div id="overlay-texture"></div>
        <div id="signal-animation-container">
          <SignalAnimation />
        </div>
        <img id="fg-img" src={foregroundIMG} alt="satellite scene" />
        <video id="bg-video" preload="auto" autoPlay loop muted playsInline>
          <source src={backgroundVID} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};
