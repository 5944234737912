import { BackgroundScene } from "./components/BackgroundScene";
import { Home } from "./pages/Home";

function App() {
  return (
    <div id="page">
      <BackgroundScene />
      <div id="page-container">
        <Home />
      </div>
    </div>
  );
}

export default App;
