// LAYOUT

export const foregroundIMG = "/images/foreground.png"; // MAIN FOREGROUND IMAGE

export const backgroundVID = "/videos/background.mp4"; // BACKGROUND VIDEO


// DATA

export const apiMetadataURL = "https://apps.eganroom.com/goes.php?locked_status=1"; // API Metadata URL

export const apiPointsDataURL = "https://home.eganroom.com/samples?size=500&pass=avj-hjq_agj1CHX.wnx"; // API Points data URL

export const apiMetadataRefreshDelay = 30000; // API Metadata Refresh Delay | 30000ms / 30s

export const apiPointsRefreshDelay = 10000; // API Points Refresh Delay | 30000ms / 30s


// DIAGRAM TWEAK

export const maxNumberOfPlotPoints = 1000; // MAXIMUM NUMBER OF PLOT POINTS ALLOWED ON CHART WHEN DRAWING 1 BY 1

export const pointColor = "#ffffff"; // COLOR

export const pointRadius = 6; // POINT RADIUS

export const pointStrokeWidth = 1; // POINT STROKE WIDTH

export const pointStrokeColor = "#05aff2"; // POINT STROKE COLOR

export const pointShadowBlur = 8; // POINT SHADOW BLUR THICKNESS

export const pointShadowColor = "#83d9fb"; // POINT SHADOW COLOR

export const drawPointDelay = 20; // delay between two iterations (in milliseconds) (20 was chosen based on 500 points every 10 seconds)

export const removeCanvasDelay = 5000; // THIS NEEDS TO BE THE SAME AMOUNT OF TIME AS THE "FADE-ANIMATION" CLASS

/*
* To change CSS fade duration edit this class in the "index.css" file and change "5s" to any amount of seconds you prefer.
*
* .fade-animation {
*  animation: fade 5s steps(90) forwards;
* -webkit-animation: fade 5s steps(90) forwards;
*  -moz-animation: fade 5s steps(90) forwards;
* }
*
*/