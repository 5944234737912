import axios from "axios";
import { apiMetadataURL, apiPointsDataURL } from "../config";

export const getApiData = async () => {
  const resp = await axios
    .get(apiMetadataURL)
    .then((res) => {
      const results = res.data;
      return results;
    })
    .catch((error) => console.log(error));
  return resp;
};

export const getPlotData = async () => {
  const resp = await axios
    .get(apiPointsDataURL)
    .then((res) => {
      const results = res.data;
      return results;
    })
    .catch((error) => console.log(error));
  return resp;
};
