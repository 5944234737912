import { useState, useEffect } from "react";
import { Stats } from "../components/Stats";
import { getApiData, getPlotData } from "../data/api";
import { apiMetadataRefreshDelay, apiPointsRefreshDelay } from "../config";
import { Charting } from "../components/Charting";

export const Home = () => {
  const [apiData, setApiData] = useState();
  const [plotData, setPlotData] = useState();

  useEffect(() => {
    async function fetchMetadata() {
      const metadata = await getApiData();
      setApiData(metadata);
    }

    async function fetchPoints() {
      const pointsdata = await getPlotData();
      setPlotData(pointsdata);
    }

    fetchMetadata();
    fetchPoints();

    const interval = setInterval(
      () => fetchMetadata(),
      apiMetadataRefreshDelay
    );
    const interval2 = setInterval(() => fetchPoints(), apiPointsRefreshDelay);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  useEffect(() => {
    var signalAnimation = document.getElementById("signal-animation-container");
    signalAnimation.style.display =
      apiData?.lock_status === "locked" ? "block" : "none";
  }, [apiData]);

  return (
    <>
      <div id="main-info-container">
        <div id="constellations-container">
          {plotData && <Charting plotData={plotData} />}
        </div>

        <div id="stats-container">{apiData && <Stats apiData={apiData} />}</div>
      </div>
    </>
  );
};
