import { useEffect, useState } from "react";
import {
  maxNumberOfPlotPoints,
  pointColor,
  pointRadius,
  drawPointDelay,
  pointStrokeWidth,
  pointStrokeColor,
  pointShadowBlur,
  pointShadowColor,
  removeCanvasDelay,
} from "../config";

export const Charting = ({ plotData }) => {
  const [currentNumberOfPoints, setCurrentNumberOfPoints] = useState(0);
  const [allPointsXY, setAllPointsXY] = useState([]);
  const [canvasCount, setCanvasCount] = useState([]);
  const [firstDraw, setFirstDraw] = useState(false);

  function drawFirstChart(data) {
    let counter = 0;
    var parent = document.getElementById("constellation");
    var can = document.getElementById("diagram-canvas-1");
    var ctx = can.getContext("2d");
    can.width = parent.offsetWidth;
    can.height = parent.offsetHeight;

    const W = ctx.canvas.width,
      H = ctx.canvas.height;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, W, H);
    ctx.setTransform(1, 0, 0, 1, W / 2, H / 2);

    var loop = function () {
      return new Promise(function (outerResolve) {
        var promise = Promise.resolve();
        var i = 0;
        var next = function () {
          var el = data[i];

          addPointToCanvas(1, el);
          ++counter;

          if (++i < data.length) {
            promise = promise.then(function () {
              return new Promise(function (resolve) {
                setTimeout(function () {
                  resolve();
                  next();
                }, drawPointDelay);
              });
            });
          } else {
            outerResolve();
          }
        };
        next();
      });
    };

    loop().then(function () {
      setCurrentNumberOfPoints(counter);
      setFirstDraw(true);
      setAllPointsXY(data);
      setCanvasCount((canvasCount) => [...canvasCount, 1]);
    });
  }

  function addPointToCanvas(canvasIndex, coordinate) {
    var can = document.getElementById("diagram-canvas-" + canvasIndex);
    var ctx = can.getContext("2d");
    var to_radians = Math.PI / 180;
    var x = coordinate[0];
    var y = coordinate[1];

    ctx.beginPath();
    ctx.moveTo(x + pointRadius, y);
    ctx.arc(x, y, pointRadius, 0 * to_radians, 360 * to_radians);
    ctx.lineWidth = pointStrokeWidth;
    ctx.strokeStyle = pointStrokeColor;
    ctx.stroke();
    ctx.fill();
    ctx.fillStyle = pointColor;
    ctx.shadowBlur = pointShadowBlur;
    ctx.shadowColor = pointShadowColor;
  }

  const setPoints = async (canvasIndex, arrData) => {
    let counter = currentNumberOfPoints;
    let currentCanvasIndex = canvasIndex;
    let prevCanvasIndex = canvasIndex - 1;
    let allPoints = allPointsXY;
    let can = document.getElementById("diagram-canvas-" + prevCanvasIndex);

    var loop = function () {
      return new Promise(function (outerResolve) {
        var promise = Promise.resolve();
        var i = 0;
        var next = function () {
          var el = arrData[i];

          if (counter >= maxNumberOfPlotPoints) {
            --counter;
            allPoints.shift();
          }

          allPoints.push(el);
          addPointToCanvas(currentCanvasIndex, el);
          ++counter;

          if (++i < arrData.length) {
            promise = promise.then(function () {
              return new Promise(function (resolve) {
                setTimeout(function () {
                  resolve();
                  next();
                }, drawPointDelay);
              });
            });
          } else {
            outerResolve();
          }
        };
        next();
      });
    };

    loop().then(function () {
      if (counter >= maxNumberOfPlotPoints) {
        can.className = "fade-animation";
        setTimeout(function () {
          can.remove();
        }, removeCanvasDelay);

        setCanvasCount((canvasCount) =>
          canvasCount.filter((key, i) => i !== 0)
        );
      }

      setCurrentNumberOfPoints((currentNumberOfPoints) => counter);
      setAllPointsXY(allPoints);
    });
  };

  function createCanvas(index) {
    var parent = document.getElementById("constellation");
    let newCanvas = document.createElement("canvas");
    newCanvas.id = "diagram-canvas-" + index;
    newCanvas.className = "constellation-canvas";
    let ctx = newCanvas.getContext("2d");
    parent.prepend(newCanvas);
    newCanvas.width = parent.offsetWidth;
    newCanvas.height = parent.offsetHeight;

    const W = ctx.canvas.width,
      H = ctx.canvas.height;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, W, H);
    ctx.setTransform(1, 0, 0, 1, W / 2, H / 2);
    console.log(index);

    setCanvasCount((canvasCount) => [...canvasCount, index]);
  }

  useEffect(() => {
    drawFirstChart(plotData);
  }, []);

  useEffect(() => {
    if (firstDraw) {
      let theCanvasIndex = ++canvasCount[canvasCount.length - 1];
      createCanvas(theCanvasIndex);
      setPoints(theCanvasIndex, plotData);
    }
  }, [plotData]);

  return (
    <>
      <div id="constellation-content">
        <div id="constellation">
          <canvas
            id="diagram-canvas-1"
            className="constellation-canvas"
            width="100%"
            height="100%"
          ></canvas>
        </div>
      </div>
    </>
  );
};
