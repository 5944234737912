import CircularWithValueLabel from "./CircularProgress";

export const Stats = ({ apiData }) => {
  const eventLog = apiData.event_log;

  return (
    <>
      <div id="stats-meta">
        <div>
          <div id="signal-status" className="stat-row flex column-flow">
            <div
              className={
                apiData.lock_status === "locked" ? "locked" : "unlocked"
              }
            >
              {apiData.lock_status}
            </div>
          </div>

          <div className="flex">
            <div className="stat-row flex column-flow">
              <div className="stat-value-container">
                <CircularWithValueLabel
                  numberVal={parseInt(
                    apiData.lock_status === "locked"
                      ? apiData.signal_quality
                      : 0
                  )}
                />
              </div>
              <div className="stat-name">Signal Quality</div>
            </div>

            <div className="stat-row flex column-flow">
              <div className="stat-value-container">
                <div className="stat-value">
                  {apiData.lock_status === "locked"
                    ? apiData.viterbi_errors
                    : 0}
                </div>
              </div>
              <div className="stat-name">Viterbi Errors</div>
            </div>

            <div className="stat-row flex column-flow">
              <div className="stat-value-container">
                <div className="stat-value">
                  {apiData.lock_status === "locked" ? apiData.rs_errors : 0}
                </div>
              </div>
              <div className="stat-name">RS Errors</div>
            </div>
          </div>
        </div>

        <div id="event-log" className="stat-row flex column-flow">
          <div className="stat-name">Event Log</div>
          <div id="log-response-container">
            {eventLog.map((keys) => {
              return Object.keys(keys).map((key, i) => {
                return (
                  <div key={i} className={"event event-log-" + key}>
                    {keys[key]}
                  </div>
                );
              });
            })}
          </div>
        </div>
      </div>
    </>
  );
};
