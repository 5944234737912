import Lottie from "react-lottie";
import * as animationData from "../lottie/rings";

export default function SignalAnimation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} widht={2560} height={1440} />;
}
